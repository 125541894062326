import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  padding-top: 70px;
`;

const HeroSection = styled.section`
  background: linear-gradient(135deg, #EDF2F7 0%, #E2E8F0 100%);
  padding: 4rem 2rem;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 1.5rem;
  font-weight: 400;
  text-align: center;

  span {
    font-weight: 600;
    background: linear-gradient(135deg, #4299e1, #48bb78);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Subtitle = styled.p`
  color: #4a5568;
  text-align: center;
  max-width: 700px;
  margin: 0 auto 3rem;
  font-size: 1.25rem;
  line-height: 1.6;
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 4rem 2rem;
`;

const ProductCard = styled.div`
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const ProductImage = styled.div`
  height: 200px;
  background: ${props => props.gradient};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;

  svg {
    width: 64px;
    height: 64px;
    stroke-width: 1.5;
  }
`;

const ProductContent = styled.div`
  padding: 1.5rem;
`;

const ProductTitle = styled.h3`
  color: #2d3748;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const ProductDescription = styled.p`
  color: #4a5568;
  line-height: 1.6;
  font-size: 0.95rem;
`;

const QualitySection = styled.section`
  background: #F7FAFC;
  padding: 4rem 2rem;
`;

const QualityGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const QualityItem = styled.div`
  text-align: center;
  padding: 1.5rem;
`;

const QualityIcon = styled.div`
  width: 48px;
  height: 48px;
  margin: 0 auto 1rem;
  color: #4299e1;

  svg {
    width: 100%;
    height: 100%;
    stroke-width: 1.5;
  }
`;

const QualityTitle = styled.h4`
  color: #2d3748;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const QualityText = styled.p`
  color: #4a5568;
  line-height: 1.6;
  font-size: 0.95rem;
`;

const Products = () => {
  const products = [
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
        </svg>
      ),
      title: 'Cooking Oil',
      description: 'Quality cooking oils for both household and commercial use. Available in various packaging sizes to meet different needs.',
      gradient: 'linear-gradient(135deg, #4299e1, #3182ce)'
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
        </svg>
      ),
      title: 'Sugar',
      description: 'Quality sugar products distributed with care to maintain freshness throughout the supply chain.',
      gradient: 'linear-gradient(135deg, #48bb78, #38a169)'
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.871c1.355 0 2.697.056 4.024.166C17.155 8.51 18 9.473 18 10.608v2.513M15 8.25v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.379a48.474 48.474 0 00-6-.371c-2.032 0-4.034.126-6 .371m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.169c0 .621-.504 1.125-1.125 1.125H4.125C3.504 21.75 3 21.246 3 20.625v-5.169c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z" />
        </svg>
      ),
      title: 'Flour',
      description: 'Premium quality flour suitable for various baking and cooking needs, delivered with consistent quality standards.',
      gradient: 'linear-gradient(135deg, #ed64a6, #d53f8c)'
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5" />
        </svg>
      ),
      title: 'Salt',
      description: 'Essential salt products distributed with attention to quality and proper storage conditions.',
      gradient: 'linear-gradient(135deg, #667eea, #764ba2)'
    }
  ];

  return (
    <PageContainer>
      <HeroSection>
        <Container>
          <Title>Essential <span>Products</span></Title>
          <Subtitle>
            Reliable distribution of quality commodities to meet the daily needs of East Java's communities.
          </Subtitle>
        </Container>
      </HeroSection>

      <Container>
        <ProductsGrid>
          {products.map((product, index) => (
            <ProductCard key={index}>
              <ProductImage gradient={product.gradient}>
                {product.icon}
              </ProductImage>
              <ProductContent>
                <ProductTitle>{product.title}</ProductTitle>
                <ProductDescription>{product.description}</ProductDescription>
              </ProductContent>
            </ProductCard>
          ))}
        </ProductsGrid>
      </Container>

      <QualitySection>
        <Container>
          <Title>Our <span>Commitment</span></Title>
          <Subtitle>
            Since 2016, we've enhanced our services with modern practices while maintaining our core values.
          </Subtitle>
          
          <QualityGrid>
            <QualityItem>
              <QualityIcon>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                </svg>
              </QualityIcon>
              <QualityTitle>Quality Assurance</QualityTitle>
              <QualityText>
                Implementation of modern quality control systems to ensure product integrity throughout distribution.
              </QualityText>
            </QualityItem>

            <QualityItem>
              <QualityIcon>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                </svg>
              </QualityIcon>
              <QualityTitle>Efficient Systems</QualityTitle>
              <QualityText>
                Digital inventory and tracking systems for improved accuracy and delivery timing.
              </QualityText>
            </QualityItem>

            <QualityItem>
              <QualityIcon>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                </svg>
              </QualityIcon>
              <QualityTitle>Customer Focus</QualityTitle>
              <QualityText>
                Responsive customer service and flexible delivery options to meet varying needs.
              </QualityText>
            </QualityItem>
          </QualityGrid>
        </Container>
      </QualitySection>
    </PageContainer>
  );
};

export default Products;
