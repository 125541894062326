import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  padding-top: 70px;
`;

const HeroSection = styled.section`
  background: linear-gradient(135deg, #EDF2F7 0%, #E2E8F0 100%);
  padding: 4rem 2rem;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #4a5568;
  margin-bottom: 1.5rem;
  font-weight: 400;
  text-align: center;

  span {
    font-weight: 400;
    background: linear-gradient(135deg, #4299e1, #48bb78);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Subtitle = styled.p`
  color: #4a5568;
  text-align: center;
  max-width: 700px;
  margin: 0 auto 3rem;
  font-size: 1.25rem;
  line-height: 1.6;
`;

const ContactSection = styled.section`
  padding: 4rem 2rem;
  background: white;
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 900px;
  margin: 0 auto;
`;

const ContactCard = styled.div`
  background: white;
  padding: 2rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  text-align: center;
`;

const ContactIcon = styled.div`
  width: 48px;
  height: 48px;
  margin: 0 auto 1.5rem;
  color: #4299e1;

  svg {
    width: 100%;
    height: 100%;
    stroke-width: 1.5;
  }
`;

const ContactTitle = styled.h3`
  color: #4a5568;
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 1.25rem;
`;

const ContactText = styled.p`
  color: #4a5568;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const ContactLink = styled.a`
  color: #4299e1;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #2b6cb0;
  }
`;

const MessageSection = styled.section`
  padding: 4rem 2rem;
  background: #F7FAFC;
`;

const MessageContent = styled.div`
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
`;

const MessageText = styled.p`
  color: #4a5568;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
`;

const Contact = () => {
  return (
    <PageContainer>
      <HeroSection>
        <Container>
          <Title>Get in <span>Touch</span></Title>
          <Subtitle>
            We value our relationships with customers and partners. Reach out to us for any inquiries.
          </Subtitle>
        </Container>
      </HeroSection>

      <ContactSection>
        <Container>
          <ContactGrid>
            <ContactCard>
              <ContactIcon>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>
              </ContactIcon>
              <ContactTitle>Email Us</ContactTitle>
              <ContactText>For inquiries and support:</ContactText>
              <ContactLink href="mailto:hello@sabarjaya.com">hello@sabarjaya.com</ContactLink>
            </ContactCard>

            <ContactCard>
              <ContactIcon>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                </svg>
              </ContactIcon>
              <ContactTitle>Location</ContactTitle>
              <ContactText>
                Our main office is located in Ponorogo, East Java, strategically positioned to serve our distribution network.
              </ContactText>
            </ContactCard>
          </ContactGrid>
        </Container>
      </ContactSection>

      <MessageSection>
        <Container>
          <MessageContent>
            <Title>Our <span>Commitment</span></Title>
            <MessageText>
              Since 1980, Sabarjaya Group has been committed to providing reliable distribution services
              to our partners throughout East Java. Our team is dedicated to maintaining the highest
              standards of service and building lasting relationships with our customers.
            </MessageText>
            <MessageText>
              Whether you're a long-time partner or interested in working with us, we're here to
              support your business needs with our extensive experience and reliable distribution network.
            </MessageText>
          </MessageContent>
        </Container>
      </MessageSection>
    </PageContainer>
  );
};

export default Contact;
