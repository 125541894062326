import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  background: rgba(255, 255, 255, 0.98);
  border-bottom: 1px solid #eaeaea;
  z-index: 1000;
  backdrop-filter: blur(5px);
`;

const HeaderContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const Logo = styled(Link)`
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem;
  color: #2d3748;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
  text-decoration: none;
  z-index: 1001;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }

  &:hover {
    color: #4299e1;
  }

  span {
    font-weight: 600;
    background: linear-gradient(135deg, #4299e1, #48bb78);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 2rem;
  align-items: center;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    right: ${props => props.isOpen ? '0' : '-100%'};
    height: 100vh;
    width: 250px;
    background: white;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
    transition: right 0.3s ease;
    box-shadow: ${props => props.isOpen ? '-5px 0 15px rgba(0, 0, 0, 0.1)' : 'none'};
  }
`;

const NavLink = styled(Link)`
  font-family: 'Inter', sans-serif;
  color: #4a5568;
  text-decoration: none;
  font-size: 1rem;
  position: relative;
  padding: 0.5rem 0;
  transition: color 0.3s ease;
  display: inline-block;
  
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, #4299e1, #48bb78);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }
  
  &:hover, &.active {
    color: #2d3748;
    
    &:before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    width: 100%;
    text-align: center;
  }
`;

const ContactButton = styled(Link)`
  background: transparent;
  border: 2px solid #4299e1;
  color: #4299e1;
  padding: 0.5rem 1.25rem;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  display: inline-block;
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #4299e1, #48bb78);
    transition: transform 0.3s ease;
    z-index: -1;
  }
  
  &:hover {
    color: white;
    border-color: transparent;
    
    &:before {
      transform: translateX(100%);
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 1001;

  @media (max-width: 768px) {
    display: block;
  }

  svg {
    width: 24px;
    height: 24px;
    color: #4a5568;
  }
`;

const Overlay = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'block' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
`;

const Header = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = !isMenuOpen ? 'hidden' : '';
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = '';
  };

  return (
    <HeaderContainer>
      <HeaderContent>
        <Logo to="/" onClick={closeMenu}>
          Sabarjaya<span>Group</span>
        </Logo>
        <MenuButton onClick={toggleMenu}>
          {isMenuOpen ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          )}
        </MenuButton>
        <Overlay isOpen={isMenuOpen} onClick={closeMenu} />
        <Nav isOpen={isMenuOpen}>
          <NavLink to="/" className={location.pathname === '/' ? 'active' : ''} onClick={closeMenu}>
            Home
          </NavLink>
          <NavLink to="/about" className={location.pathname === '/about' ? 'active' : ''} onClick={closeMenu}>
            About Us
          </NavLink>
          <NavLink to="/products" className={location.pathname === '/products' ? 'active' : ''} onClick={closeMenu}>
            Products
          </NavLink>
          <NavLink to="/infrastructure" className={location.pathname === '/infrastructure' ? 'active' : ''} onClick={closeMenu}>
            Infrastructure
          </NavLink>
          <NavLink to="/distribution" className={location.pathname === '/distribution' ? 'active' : ''} onClick={closeMenu}>
            Distribution
          </NavLink>
          <ContactButton to="/contact" onClick={closeMenu}>Contact Us</ContactButton>
        </Nav>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
