import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  padding-top: 70px;
`;

const HeroSection = styled.section`
  background: linear-gradient(135deg, #EDF2F7 0%, #E2E8F0 100%);
  padding: 4rem 2rem;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #4a5568;
  margin-bottom: 1.5rem;
  font-weight: 400;
  text-align: center;

  span {
    font-weight: 400;
    background: linear-gradient(135deg, #4299e1, #48bb78);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Subtitle = styled.p`
  color: #4a5568;
  text-align: center;
  max-width: 700px;
  margin: 0 auto 3rem;
  font-size: 1.25rem;
  line-height: 1.6;
`;

const ServicesSection = styled.section`
  padding: 4rem 2rem;
  background: white;
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const ServiceCard = styled.div`
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 1.5rem;
  transition: all 0.3s ease;
`;

const ServiceIcon = styled.div`
  width: 40px;
  height: 40px;
  margin-bottom: 1rem;
  color: #4299e1;

  svg {
    width: 100%;
    height: 100%;
    stroke-width: 1.5;
  }
`;

const ServiceTitle = styled.h3`
  color: #4a5568;
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 1.25rem;
`;

const ServiceText = styled.p`
  color: #4a5568;
  line-height: 1.6;
  font-size: 0.95rem;
`;

const RegionsSection = styled.section`
  padding: 4rem 2rem;
  background: #F7FAFC;
`;

const RegionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
`;

const RegionCard = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
`;

const RegionName = styled.h4`
  color: #4a5568;
  margin-bottom: 1rem;
  font-weight: 400;
`;

const RegionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const RegionItem = styled.li`
  color: #4a5568;
  padding: 0.5rem 0;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    width: 16px;
    height: 16px;
    stroke-width: 2;
    color: #48bb78;
  }
`;

const Distribution = () => {
  return (
    <PageContainer>
      <HeroSection>
        <Container>
          <Title>Distribution <span>Network</span></Title>
          <Subtitle>
            Serving East Java with reliable and efficient distribution services since 1980.
          </Subtitle>
        </Container>
      </HeroSection>

      <ServicesSection>
        <Container>
          <ServiceGrid>
            <ServiceCard>
              <ServiceIcon>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                </svg>
              </ServiceIcon>
              <ServiceTitle>Quick Response</ServiceTitle>
              <ServiceText>
                Efficient order processing and flexible delivery options for urgent requirements.
              </ServiceText>
            </ServiceCard>

            <ServiceCard>
              <ServiceIcon>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                </svg>
              </ServiceIcon>
              <ServiceTitle>Quality Assurance</ServiceTitle>
              <ServiceText>
                Temperature-controlled transport and proper handling to maintain product quality during distribution.
              </ServiceText>
            </ServiceCard>

            <ServiceCard>
              <ServiceIcon>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                </svg>
              </ServiceIcon>
              <ServiceTitle>Reliable Fleet</ServiceTitle>
              <ServiceText>
                Well-maintained transportation fleet ensuring timely and dependable delivery services.
              </ServiceText>
            </ServiceCard>
          </ServiceGrid>
        </Container>
      </ServicesSection>

      <RegionsSection>
        <Container>
          <Title>Service <span>Areas</span></Title>
          <Subtitle>
            Strategic coverage across East Java's key regions.
          </Subtitle>

          <RegionGrid>
            <RegionCard>
              <RegionName>Primary Service Areas</RegionName>
              <RegionList>
                <RegionItem>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                  Ponorogo
                </RegionItem>
                <RegionItem>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                  Madiun
                </RegionItem>
                <RegionItem>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                  Surrounding Areas
                </RegionItem>
              </RegionList>
            </RegionCard>

            <RegionCard>
              <RegionName>Extended Coverage</RegionName>
              <RegionList>
                <RegionItem>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                  Magetan
                </RegionItem>
                <RegionItem>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                  Ngawi
                </RegionItem>
                <RegionItem>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                  Pacitan
                </RegionItem>
              </RegionList>
            </RegionCard>
          </RegionGrid>
        </Container>
      </RegionsSection>
    </PageContainer>
  );
};

export default Distribution;
