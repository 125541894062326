import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeroSection = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  padding: 2rem;
  padding-top: 70px;
  background: linear-gradient(135deg, #EDF2F7 0%, #E2E8F0 100%);
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(135deg, rgba(66, 153, 225, 0.1) 0%, rgba(72, 187, 120, 0.1) 100%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 20% 0);
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const Content = styled.div`
  max-width: 600px;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 400;
  color: #2d3748;
  margin-bottom: 1.5rem;
  line-height: 1.2;

  span {
    font-weight: 600;
    background: linear-gradient(135deg, #4299e1, #48bb78);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  color: #4a5568;
  margin-bottom: 2rem;
  line-height: 1.6;
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
`;

const StatItem = styled.div`
  text-align: left;
`;

const StatNumber = styled.div`
  font-size: 2.5rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 0.5rem;
  background: linear-gradient(135deg, #4299e1, #48bb78);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StatLabel = styled.div`
  color: #4a5568;
  font-size: 1rem;
`;

const CTAButton = styled(Link)`
  display: inline-block;
  background: linear-gradient(135deg, #4299e1 0%, #3182ce 100%);
  color: white;
  padding: 1rem 2rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
  }
`;

const Home = () => {
  return (
    <>
      <HeroSection>
        <Container>
          <Content>
            <Title>
              East Java's Premier
              <br />
              <span>Distribution Partner</span>
            </Title>
            <Subtitle>
              Since 1980, Sabarjaya Group has been a trusted name in essential commodity distribution,
              modernized in 2016 to meet evolving market demands.
            </Subtitle>
            <CTAButton to="/about">Discover Our Legacy</CTAButton>
            
            <StatsContainer>
              <StatItem>
                <StatNumber>40+</StatNumber>
                <StatLabel>Years of Excellence</StatLabel>
              </StatItem>
              <StatItem>
                <StatNumber>1000+</StatNumber>
                <StatLabel>Distribution Points</StatLabel>
              </StatItem>
              <StatItem>
                <StatNumber>4</StatNumber>
                <StatLabel>Core Products</StatLabel>
              </StatItem>
            </StatsContainer>
          </Content>
        </Container>
      </HeroSection>
    </>
  );
};

export default Home;
