import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  padding-top: 70px;
`;

const HeroSection = styled.section`
  background: linear-gradient(135deg, #EDF2F7 0%, #E2E8F0 100%);
  padding: 4rem 2rem;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #4a5568;
  margin-bottom: 1.5rem;
  font-weight: 400;
  text-align: center;

  span {
    font-weight: 400;
    background: linear-gradient(135deg, #4299e1, #48bb78);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Subtitle = styled.p`
  color: #4a5568;
  text-align: center;
  max-width: 700px;
  margin: 0 auto 3rem;
  font-size: 1.25rem;
  line-height: 1.6;
`;

const FacilitiesSection = styled.section`
  padding: 4rem 2rem;
  background: white;
`;

const FacilitiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const FacilityCard = styled.div`
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
`;

const FacilityHeader = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const FacilityIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4299e1;

  svg {
    width: 24px;
    height: 24px;
    stroke-width: 1.5;
  }
`;

const FacilityTitle = styled.h3`
  color: #4a5568;
  font-weight: 400;
  font-size: 1.25rem;
`;

const FacilityContent = styled.div`
  padding: 1.5rem;
`;

const FacilityList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const FacilityItem = styled.li`
  color: #4a5568;
  padding: 0.75rem 0;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.95rem;

  &:last-child {
    border-bottom: none;
  }
`;

const FacilityLabel = styled.span``;

const FacilityValue = styled.span`
  color: #2d3748;
  text-align: right;
`;

const ModernizationSection = styled.section`
  padding: 4rem 2rem;
  background: #F7FAFC;
`;

const ModernizationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const ModernizationCard = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
`;

const ModernizationTitle = styled.h4`
  color: #4a5568;
  margin-bottom: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const ModernizationText = styled.p`
  color: #4a5568;
  line-height: 1.6;
  font-size: 0.95rem;
`;

const Infrastructure = () => {
  return (
    <PageContainer>
      <HeroSection>
        <Container>
          <Title>Our <span>Infrastructure</span></Title>
          <Subtitle>
            Supporting East Java's distribution needs with modern facilities and efficient systems.
          </Subtitle>
        </Container>
      </HeroSection>

      <FacilitiesSection>
        <Container>
          <FacilitiesGrid>
            <FacilityCard>
              <FacilityHeader>
                <FacilityIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819" />
                  </svg>
                </FacilityIcon>
                <FacilityTitle>Storage Facilities</FacilityTitle>
              </FacilityHeader>
              <FacilityContent>
                <FacilityList>
                  <FacilityItem>
                    <FacilityLabel>Temperature Control</FacilityLabel>
                    <FacilityValue>Modern Systems</FacilityValue>
                  </FacilityItem>
                  <FacilityItem>
                    <FacilityLabel>Inventory Management</FacilityLabel>
                    <FacilityValue>Digital Tracking</FacilityValue>
                  </FacilityItem>
                  <FacilityItem>
                    <FacilityLabel>Security Systems</FacilityLabel>
                    <FacilityValue>24/7 Monitoring</FacilityValue>
                  </FacilityItem>
                </FacilityList>
              </FacilityContent>
            </FacilityCard>

            <FacilityCard>
              <FacilityHeader>
                <FacilityIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                  </svg>
                </FacilityIcon>
                <FacilityTitle>Transportation Fleet</FacilityTitle>
              </FacilityHeader>
              <FacilityContent>
                <FacilityList>
                  <FacilityItem>
                    <FacilityLabel>Fleet Management</FacilityLabel>
                    <FacilityValue>Systematic</FacilityValue>
                  </FacilityItem>
                  <FacilityItem>
                    <FacilityLabel>Fleet Maintenance</FacilityLabel>
                    <FacilityValue>Scheduled</FacilityValue>
                  </FacilityItem>
                  <FacilityItem>
                    <FacilityLabel>Delivery Planning</FacilityLabel>
                    <FacilityValue>Optimized</FacilityValue>
                  </FacilityItem>
                </FacilityList>
              </FacilityContent>
            </FacilityCard>
          </FacilitiesGrid>
        </Container>
      </FacilitiesSection>

      <ModernizationSection>
        <Container>
          <Title>Modern <span>Improvements</span></Title>
          <Subtitle>
            Since 2016, we've implemented various improvements to enhance our service quality.
          </Subtitle>
          
          <ModernizationGrid>
            <ModernizationCard>
              <ModernizationTitle>
                <FacilityIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                  </svg>
                </FacilityIcon>
                Digital Inventory System
              </ModernizationTitle>
              <ModernizationText>
                Real-time tracking of stock levels and automated reordering systems for efficient inventory management.
              </ModernizationText>
            </ModernizationCard>

            <ModernizationCard>
              <ModernizationTitle>
                <FacilityIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                  </svg>
                </FacilityIcon>
                Smart Logistics
              </ModernizationTitle>
              <ModernizationText>
                Route optimization and delivery scheduling to ensure timely and efficient distribution.
              </ModernizationText>
            </ModernizationCard>

            <ModernizationCard>
              <ModernizationTitle>
                <FacilityIcon>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                  </svg>
                </FacilityIcon>
                Customer Portal
              </ModernizationTitle>
              <ModernizationText>
                Online ordering and tracking system for improved customer service and transparency.
              </ModernizationText>
            </ModernizationCard>
          </ModernizationGrid>
        </Container>
      </ModernizationSection>
    </PageContainer>
  );
};

export default Infrastructure;
