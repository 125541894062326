import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  padding-top: 70px;
`;

const HeroSection = styled.section`
  background: linear-gradient(135deg, #EDF2F7 0%, #E2E8F0 100%);
  padding: 4rem 2rem;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #2d3748;
  margin-bottom: 1.5rem;
  font-weight: 400;
  text-align: center;

  span {
    font-weight: 600;
    background: linear-gradient(135deg, #4299e1, #48bb78);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Subtitle = styled.p`
  color: #4a5568;
  text-align: center;
  max-width: 700px;
  margin: 0 auto 3rem;
  font-size: 1.25rem;
  line-height: 1.6;
`;

const TimelineSection = styled.section`
  padding: 4rem 2rem;
  background: white;
`;

const Timeline = styled.div`
  position: relative;
  max-width: 800px;
  margin: 0 auto;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background: #E2E8F0;
  }
`;

const TimelineItem = styled.div`
  display: flex;
  justify-content: ${props => props.align === 'right' ? 'flex-end' : 'flex-start'};
  padding-bottom: 3rem;
  width: 100%;
  position: relative;

  &:last-child {
    padding-bottom: 0;
  }
`;

const TimelineContent = styled.div`
  width: 45%;
  padding: 1.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: white;
    border: 4px solid #4299e1;
    border-radius: 50%;
    top: 20px;
    ${props => props.align === 'right' ? 'left: -60px;' : 'right: -60px;'}
  }
`;

const Year = styled.div`
  font-weight: 600;
  color: #4299e1;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
`;

const TimelineTitle = styled.h3`
  color: #2d3748;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const TimelineText = styled.p`
  color: #4a5568;
  line-height: 1.6;
`;

const ValuesSection = styled.section`
  padding: 4rem 2rem;
  background: #F7FAFC;
`;

const ValuesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
`;

const ValueCard = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const ValueTitle = styled.h3`
  color: #2d3748;
  margin-bottom: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const ValueIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.color}15;
  color: ${props => props.color};
  border-radius: 8px;

  svg {
    width: 20px;
    height: 20px;
    stroke-width: 1.5;
  }
`;

const About = () => {
  return (
    <PageContainer>
      <HeroSection>
        <Container>
          <Title>Our <span>Journey</span></Title>
          <Subtitle>
            From our humble beginnings in the 1980s to becoming East Java's leading distribution company,
            our story is one of continuous growth and adaptation.
          </Subtitle>
        </Container>
      </HeroSection>

      <TimelineSection>
        <Container>
          <Timeline>
            <TimelineItem align="left">
              <TimelineContent align="left">
                <Year>1980</Year>
                <TimelineTitle>Foundation</TimelineTitle>
                <TimelineText>
                  Established as a small distribution company in East Java, focusing on essential commodities.
                </TimelineText>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem align="right">
              <TimelineContent align="right">
                <Year>1990s</Year>
                <TimelineTitle>Regional Expansion</TimelineTitle>
                <TimelineText>
                  Expanded our distribution network across East Java, establishing key partnerships with suppliers.
                </TimelineText>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem align="left">
              <TimelineContent align="left">
                <Year>2000s</Year>
                <TimelineTitle>Infrastructure Development</TimelineTitle>
                <TimelineText>
                  Invested in modern warehousing facilities and transportation fleet to enhance distribution capabilities.
                </TimelineText>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem align="right">
              <TimelineContent align="right">
                <Year>2016</Year>
                <TimelineTitle>Modern Transformation</TimelineTitle>
                <TimelineText>
                  Second generation leadership modernized operations with digital systems and expanded product portfolio.
                </TimelineText>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Container>
      </TimelineSection>

      <ValuesSection>
        <Container>
          <Title>Our <span>Values</span></Title>
          <Subtitle>
            Our core values guide every decision we make and every relationship we build.
          </Subtitle>
          
          <ValuesGrid>
            <ValueCard>
              <ValueTitle>
                <ValueIcon color="#4299e1">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                  </svg>
                </ValueIcon>
                Trust & Reliability
              </ValueTitle>
              <TimelineText>
                Building lasting relationships through consistent, reliable service and transparent business practices.
              </TimelineText>
            </ValueCard>

            <ValueCard>
              <ValueTitle>
                <ValueIcon color="#48bb78">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                  </svg>
                </ValueIcon>
                Innovation
              </ValueTitle>
              <TimelineText>
                Embracing modern solutions while maintaining the strong foundation built over decades.
              </TimelineText>
            </ValueCard>

            <ValueCard>
              <ValueTitle>
                <ValueIcon color="#ed64a6">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                  </svg>
                </ValueIcon>
                Community Focus
              </ValueTitle>
              <TimelineText>
                Supporting East Java's growth by ensuring reliable access to essential commodities.
              </TimelineText>
            </ValueCard>
          </ValuesGrid>
        </Container>
      </ValuesSection>
    </PageContainer>
  );
};

export default About;
